const apiCalls = {
  loginUser: "auth/login",
  logoutUser: "auth/logout",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  Uploads: "bulkuploadStatus",
  employees: "employees",
  users: "users",
  hospitals: "hospitals",
  clinics: "clinics",
  patients: "patients",
  templates: "templates",
  forms: "forms",
  schedules: "schedules",
  chairs: "chairs",
  appointments: "appointments",
  procedures: "procedures",
  txPlans: "txPlans",
  treatments: "treatments",
  prescriptions: "prescriptions",
  pharmacies: "pharmacies",
  treatmentPlans: "treatmentPlans",
  drugs: "drugs",
  reasons: "reasons",
  insurances: "insurances",
  carriers: "carriers",
  benifits: "benifits",
  feeschedules: "feeschedules",
  patientEligibilities: "patientEligibilities",
  releaseNotes: "releaseNotes",
  proceduresLists: "proceduresLists",
  chartProcedures: "chartProcedures",
  clockInAndClockOuts: "clockInAndClockOuts",
  availablePrompts: "availablePrompts",
  autoNotes: "autoNotes",
  chartHistories: "chartHistories",
  uploadTeethImages: "uploadTeethImages",
  patientNotes: "patientNotes",
  referrals: "referrals",
  accounts: "accounts",
  makePayments: "makePayments",
  imageMaintainings: "imageMaintainings",
  perioCharts: "perioCharts",
  payments: "payments",
  claimManagements: "claimManagements",
  analyticAdmins: "analyticAdmins",
  paidHistories: "paidHistories",
};

// {"loginUser":"auth/login","logoutUser":"auth/logout","loginChangePassword":"auth/loginChangePassword","MenuList":"menus","Settings":"settings","LocationImagePath":"uploads?uploadWhileCreate=true&uploadPath=location","ProfileImagePath":"uploads?uploadWhileCreate=true&uploadPath=employee","dashboard":"dashboard/counts","employee":"employees","employees":"employees","Uploads":"bulkuploadStatus"}

export default apiCalls;
